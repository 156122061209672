/**
 * Global styles
 */
*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  //background-color: $base-background;
  color: $base-text-color;
  font-family: $base-font-family;
  //font-weight: $base-font-weight;
  font-size: 100%;
  line-height: $base-line-height;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  -webkit-text-size-adjust: none;
  width: 100%;

  /**
   * Add breakpoint values to <body> and parse them
   * to js function (breakpoints.js).
   *
   * Source: https://www.lullabot.com/blog/article/importing-css-breakpoints-javascript
   */
  &:before {
    content: 'small';
    display: none;

    @include breakpoint(medium) {
      content: 'medium';
    }

    @include breakpoint(large) {
      content: 'large';
    }
  }

  /**
   * Used by disableHover.js
   */
  &.disable-hover {
    pointer-events: none;
  }
}

::-moz-selection {
  background: $base-selection-background;
  color: $base-selection-color;
  text-shadow: none;
}

::selection {
  background: $base-selection-background;
  color: $base-selection-color;
  text-shadow: none;
}

figure {
  margin: 0;
}

a {
  color: $base-link-color;
  text-decoration: none;
  transition: $base-transition;

  &:hover,
  &:active {
    color: $base-link-color-hover;
  }
}

h1,
h2,
h3,
h4,
h5 {
  color: palette(ui, 'black');
  font-family: 'Lato';
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

h6 {
  @include font-copy-meta;
  font-weight: bold;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

.hr {
  margin-bottom: 2em;

  @include breakpoint(large) {
    @include grid-column-row;
  }

  hr {
    background: palette(ui, 'gray-light');
    border: none;
    height: 1px;
  }
}


.nobr {
  white-space: nowrap;
}



/** **************
 * Sticky footer
 *
 * @see https://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/
 */
.global-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;

  .global-content {
    flex: 1 0 auto;

    &:after {
      content: '\00a0'; /* &nbsp; */
      display: block;
      height: 0;
      visibility: hidden;
    }
  }
}

.navbar,
.footer {
  flex: none;
}




// Form styles
.form__messages {
  span {
    display: none;
  }
}

input[type='text'],
input[type='email'],
textarea {
  &.error {
    border-color: palette(ui, 'error') !important;
  }
}
