// Masonry
//
// @see http://salvattore.com/

[data-columns]::before {
  color: transparent;
  content: '1 .column.size-1of1';
}

@include breakpoint(large) {
  [data-columns]::before {
    color: transparent;
    content: '2 .column.size-1of2';
	}
}

/* Again, you’re free to use and define the classes: */
.column {
  float: left;
}

.size-1of1 {
  @include module-padding;
  width: 100%;

  @include breakpoint(large) {
    padding: 0;
  }
}

.size-1of2 {
  @include breakpoint(medium) {
    width: 50%;
  }

}

.size-1of3 {
  width: 33.333%;
}
