/// Accordion
///
///

@import 'config';
@import 'mixins';

.c-accordion {
  @include c-accordion;
}
