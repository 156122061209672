/// Navbar (not menu)
///
///

@import 'config';
@import 'mixins';

.navbar {
  @include grid-column-row;
  padding: rem-calc(30) 0 0;
  font-family: 'Lato';
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: normal;
  font-size: 14px;
  position: relative;

  @include breakpoint($navbar-breakpoint) {
    //@include flex-grid-row();
    //@include flex-align(left, middle);
  }

  a {
    color: darken($base-text-color, 40%);
    text-decoration: none;
  }
}



.navbar__logo {
  //flex: 0 0 ($navbar-logo-width/1.6);
  display: block;
  float: left;
  margin-left: rem-calc(20);
  padding-bottom: rem-calc(30);
  width: $navbar-logo-width/1.6;

  @include breakpoint($navbar-breakpoint) {
    //@include flex;
    //flex: 0 0 $navbar-logo-width;
    //margin-left: rem-calc(10);
    width: $navbar-logo-width;
  }

  svg {
    width: 100%;
    height: 100%;
    max-width: 100%;

    @include breakpoint($navbar-breakpoint) {
      width: rem-calc(160);
      height: rem-calc(83);
    }
  }
}
