/// Global module mixins
///
/// @author Martin Szymanski

/// bottom margin of module
@mixin module-padding() {

  @include breakpoint(xmedium) {
    padding: 0 rem-calc($base-module-padding-small);
  }

  @include breakpoint(medium) {
    padding: 0 rem-calc($base-module-padding-medium);
  }

  @include breakpoint(large) {
    padding: 0 rem-calc($base-module-padding-large);
  }
}

@mixin module-margin() {
  margin-bottom: rem-calc(40);

  @include breakpoint(medium) {
    margin-bottom: rem-calc(60);
  }

  @include breakpoint(large) {
    margin-bottom: rem-calc(100);
  }
}
