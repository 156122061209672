/// Language select
///
///

@import 'config';
@import 'mixins';

.map {
  @include grid-row;
  @include module-margin;
  @include module-padding;

  h2,
  h3 {
    @include font-title-small;
  }
}



.map__inner {
  @include grid-column;
}




.map__entry {
  @include font-copy;
  margin-bottom: 2em;

  @include breakpoint(medium) {
    @include grid-column(9);
    padding: 0 !important;
  }

  ul {
    list-style: none;
    margin: -1em 0 0;
    padding: 0;
  }

  li {
    display: inline-block;
    margin-right: rem-calc(15);
  }
}



.map__map {
  height: 400px;
  width: 100%;
}
