/// Site Footer
///
///

.footer {
  font-family: $base-font-label;
  padding: rem-calc(40) 0 0;
  max-width: $base-maxwidth;
  width: 100%;
  margin: rem-calc(100) auto 0;
  text-align: center;

  ul {
    @include border('button-light');
    display: inline-block;
    font-size: 14px;
    list-style: none;
    margin: 0;
    padding: rem-calc(8) rem-calc(15);
    text-align: center;
    text-transform: uppercase;
  }

  li {
    display: inline-block;
    margin: 0 rem-calc(10);
  }

  a {
    color: $base-link-color;
    text-decoration: none;
  }

  nav {
    margin-bottom: 1.5em;
  }

  address {
    font-style: normal;

    span {
      display: inline-block;
      margin: 0 rem-calc(10);
    }
  }
}








.footer__inner {
  background: $base-footer-background;
  padding: rem-calc(40) rem-calc(20) rem-calc(100);
}









.footer__logo {
  display: inline-block;
  margin-bottom: rem-calc($gutter);
  width: rem-calc($base-logo-width);

  svg {
    max-width: 100%;
  }
}
