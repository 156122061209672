/// Coloured ribbons on images
///
///

@mixin ribbon($pos: 'left') {
  @include breakpoint(large) {
    &:before {
      background: palette(ui, 'brand-light');
      content: '';
      display: block;
      width: 15%;
      height: 100%;
      position: absolute;
      z-index: -1;

      @if $pos == 'left' {
        left: 0;
        transform: translateX(-47%) translateY(30px);
      } @elseif $pos == 'right'  {
        left: auto;
        right: 0;
        transform: translateX(47%) translateY(30px);
      }
    }
  }
}
