/// Content partner detail
///
///

.content-contact {
  @include grid-row;
  @include module-margin;

  h2,
  h3 {
    @include font-title-small;
  }

  article {
    @include grid-col;

    @include breakpoint(medium) {
      @include grid-col(6);
    }
  }

  aside {
    @include grid-col;

    @include breakpoint(medium) {
      @include grid-col(6);
    }
  }
}







.content-contact__entry {
  @include font-copy;
}
