/// Border mixins
///
///

@mixin border($type) {
  @if $type == 'infobox' {
    border: 5px solid palette(ui, 'gray-light');
  }

  @if $type == 'button' {
    border: 3px solid palette(ui, 'brand-light');
  }

  @if $type == 'button-light' {
    border: 3px solid palette(ui, 'gray-light');
  }

  @if $type == 'heading' {
    &:before {
      background: palette(ui, 'brand-light');
      content: '';
      display: block;
      height: rem-calc(3);
      width: rem-calc(27);
      margin-bottom: rem-calc(10);
    }
  }
}
