/// Basic content for meta pages
///
///

.content-basic {
  @include grid-row;
  @include module-margin;
  @include module-padding;
  padding-top: rem-calc(80) !important;

  h1 {
    @include font-title;
  }

  h2,
  h3 {
    @include font-title-small;
  }

  article {
    @include grid-col;
    @include font-copy;

    @include breakpoint(medium) {
      @include grid-col(8);
    }
  }
}
