@mixin c-accordion() {
  @include button-arrow-down;
  margin-bottom: 2em;
  position: relative;

  > div {
    @include font-copy;
    max-height: 0;
    overflow: hidden;
    position: relative;
    transition: max-height .5s ease-in-out;
    z-index: 2;
  }

  h4 {
    display: inline-block;
    margin: 0 0 1em;
    position: relative;
  }

  input[type='checkbox'] {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;

    /// toggle content if checkbox if checked
    &:checked {
      ~ div {
        max-height: 10000px;
      }

      ~ h4 {
        i {
          transform: rotate(-90deg);
        }
      }
    }

  }
}
