/// Language select
///
///

@import 'config';
@import 'mixins';

.fullheader {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 80%;
  height: 300px;
  margin: 0 auto;
  margin-bottom: 2em;
  max-width: $fullheader-maxwidth;

  @include breakpoint(medium) {
    height: 700px;
  }

  img {
    @include responsive-img;
  }
}
