/// Teaser text module
///
///

@import 'config';
@import 'mixins';

.teaser {
  h2,
  h3 {
    @include border('heading');
    @include responsive-font(2.5vw, 22px, 36px, 20px);
    margin-bottom: 1em;
  }
}



.teaser__entry {
  @include font-copy;
  margin-bottom: 2em;
}
