/// Content partner detail
///
///

.content-partner {
  @include grid-row;
  @include module-padding;

  header {
    @include grid-col;

    h2 {
      @include font-title;
      @include border('heading');
      border-bottom: 1px solid palette(ui, 'gray-lighter');
      padding-bottom: 1em;
      margin-bottom: 1em;

      span {
        display: block;
      }
    }
  }

  article {
    @include grid-col;

    @include breakpoint(medium) {
      @include grid-col(6);
    }

    @include breakpoint(large) {
      @include grid-col(7);
    }

    @include breakpoint(xlarge) {
      @include grid-col(8);
    }
  }

  aside {
    @include grid-col;

    @include breakpoint(medium) {
      @include grid-col(6);
    }

    @include breakpoint(large) {
      @include grid-col(5);
    }

    @include breakpoint(xlarge) {
      @include grid-col(4);
    }
  }
}








.content-partner__entry {
  @include font-copy;
  margin-bottom: 2em;
}
