/// Language select
///
///

@import 'config';
@import 'mixins';

.languages {
  border-top: 1px solid $langugae-border-color;
  padding: rem-calc($gutter) rem-calc($gutter * 1.5);
  text-align: center;

  @include breakpoint($language-breakpoint) {
    border-top: none;
    font-size: 12px;
    padding-right: rem-calc($gutter / 1.3);
    position: absolute;
    right: 0;
    top: 0;

    a,
    .current a {
      color: palette(ui, 'gray-light') !important;
      font-weight: normal !important;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;

    &:first-child:after {
      content: ' | ';
      color: palette(ui, 'gray-lighter');
      margin-right: rem-calc(5);
    }
  }
}
