/// Buttons
///
///

@import 'config';
@import 'mixins';

.button {
  @include button;
}
