/// Thumbnail list
///
///

@import 'config';
@import 'mixins';

.thumbnails {
  @include grid-column-row;
  margin-bottom: rem-calc(80);

  header {
    @include module-padding;
  }

  h3 {
    @include font-title-small;
    @include border('heading');
  }

  ul {
    @include module-padding;
    list-style: none;
    margin: 0 -($gutter/2);
    padding: 0;
    text-align: center;

    @include breakpoint(medium) {
      text-align: left;
      margin-bottom: 0;
    }

    li {
      @include font-copy-meta;
      display: inline-block;
      margin-bottom: rem-calc($gutter);
      padding: 0 rem-calc($gutter/2);
      width: 50%;
      vertical-align: top;
      //text-align: center;


      @include breakpoint(medium) {
        display: inline-block;
        margin-bottom: 0;
        text-align: center;
        width: 20%;
      }

      img {
        @include responsive-img;
        margin-bottom: rem-calc(20);
        filter: grayscale(100%);
        height: auto;
        opacity: .5;
        transition: $base-transition;
      }

      &.is-current,
      &:hover {
        img {
          filter: grayscale(0);
          opacity: 1;
        }
      }
    }

    figcaption {
      span {
        display: block;
      }
    }
  }
}


.thumbnails {
  &.col-6 ul li {
    @include breakpoint(medium) {
      margin-bottom: rem-calc($gutter);
      width: 33%;
    }

    @include breakpoint(xlarge) {
      margin-bottom: 0;
      width: 16.66%;
    }
  }
}
