/// Content partner detail
///
///

.content-service {
  @include grid-row;
  @include module-margin;

  article {
    @include grid-column;

    @include breakpoint(medium) {
      @include grid-column(8);
    }

    > div {
      @include font-copy;
    }
  }
}




.content-service__item {
  @include module-margin;
}
