/// Navbar (not menu)
///
///

@import 'config';
@import 'mixins';

.menu {
  @include breakpoint($menu-breakpoint) {
    //@include flex;
    //flex: 1 0 calc(100% - #{$navbar-logo-width + 20px});
    //text-align: right;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .has_children {
    > ul {
      max-height: 0;
      overflow: hidden;
      transition: max-height .5s ease-out;

      @include breakpoint($menu-breakpoint) {
        //box-shadow: 0 0 16px rgba(0, 0, 0, .1);
        background: $menu-background-desktop;
        box-shadow: 0 8px 16px -6px rgba(0, 0, 0, .2);
        max-height: none;
        position: absolute;
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        //z-index: 10;
        top: 80%;
        left: -10px;
        width: rem-calc($menu-submenu-width);
        transition: none;
      }

      a {
        background: $menu-submenu-background;
        padding: rem-calc($gutter) rem-calc($gutter * 1.5) rem-calc($gutter) rem-calc($gutter * 2);

        &:hover {
          background: palette(ui, 'gray-lightest');
        }

        @include breakpoint($menu-breakpoint) {
          background: $menu-background-desktop;
          padding: rem-calc($gutter) rem-calc($gutter * 1.5);
        }
      }
    }

    &:hover {
      > ul {
        opacity: 1;
        visibility: visible;
        z-index: 1;
      }
    }

    input[type='checkbox'] {
      display: none;

      &:checked {
        ~ ul {
          max-height: 1000px;
          transition: max-height .5s ease-in;
        }

        ~ .menu__arrow {
          transform: rotate(90deg);
        }
      }
    }
  }

  &__arrow {
    background: $menu-arrow no-repeat;
    background-size: 75% 75%;
    background-position: 50% 50%;
    display: block;
    position: absolute;
    top: rem-calc($gutter / 1.5);
    right: rem-calc($gutter);
    width: 30px;
    height: 30px;
    transform: rotate(-90deg);
    z-index: index($elements, submenu);
    transition: $base-transition;
  }


  &__checkbox {
    display: none;

    &:checked ~ .menu__wrapper {
      max-height: 5000px;
      transition: max-height .5s ease-in;
    }

    &:checked ~ .menu__toggle {
      //color: $menu-color;
      //transition-delay: .3s;

      span {
        &:nth-child(1) {
          top: $menu-toggle-size/3;
          width: 0%;
          left: 50%;
        }

        &:nth-child(2) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }

        &:nth-child(4) {
          top: $menu-toggle-size/3;
          width: 0%;
          left: 50%;
        }
      }

    }

  }

  &__toggle {
    cursor: pointer;
    display: block;
    width: $menu-toggle-size;
    height: $menu-toggle-size;
    position: absolute;
    right: rem-calc(20);
    top: rem-calc($base-navbar-height / 2);

    @include breakpoint($menu-breakpoint) {
      display: none;
    }

    // animated burger menu icon
    span {
      display: block;
      position: absolute;
      height: rem-calc(2px);
      width: 100%;
      background: $menu-toggle-color;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: $base-transition;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: $menu-toggle-size/3;
      }

      &:nth-child(4) {
        top: $menu-toggle-size/1.5;
      }
    }
  }
}



.menu__wrapper {
  max-height: 0;
  transition: max-height .5s ease-out;
  overflow: hidden;
  //float: right;
  width: 100%;

  @include breakpoint($menu-breakpoint) {
    max-height: none;
    overflow: visible;
    transition: none;
  }

  ul {
    li {
      &.current,
      &.parent {
        > a {
          //color: $menu-current-color;
          font-weight: bold;
        }
      }
    }
  }

  > ul {

    @include breakpoint($menu-breakpoint) {
      height: auto;
      opacity: 1;
      visibility: visible;
      text-align: right;
    }

    li {
      border-top: 1px solid $menu-border-color;
      //display: inline-block;
      position: relative;
      text-align: left;

      @include breakpoint($menu-breakpoint) {
        border: none;
      }

      a {
        display: block;
        padding: rem-calc($gutter) rem-calc($gutter * 1.5);

        @include breakpoint($menu-breakpoint) {
          padding: 0;
        }
      }
    }

    > li {
      @include breakpoint($menu-breakpoint) {
        display: inline-block;
        position: relative;

        &:hover {
          > a {
            color: $menu-current-color;
          }
        }
      }

      > a {
        @include breakpoint($menu-breakpoint) {
          display: inline-block;
          height: $menu-item-height;
          line-height: $menu-item-height;
          padding: 0 rem-calc($menu-item-margin);
        }
      }

      &:last-child {
        > a {
          @include breakpoint($menu-breakpoint) {
            padding-right: 0;
          }
        }
      }
    }
  }
}
