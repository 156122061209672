/// COntact box
///
///

@import 'config';
@import 'mixins';

.contactbox {
  @include module-margin;

  h3 {
    @include font-title-small;
    margin-top: 0;
  }

  dl {
    @include font-copy;
    margin-bottom: rem-calc(60);

    &:last-child {
      margin-bottom: 0;
    }
  }

  dt {
    font-weight: bold;
  }

  dd {
    margin: 0 0 1em;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}







.contactbox__inner {
  @include border('infobox');
  padding: rem-calc(40);

  @include breakpoint(medium) {
    padding: rem-calc(30);
  }

  @include breakpoint(large) {
    padding: rem-calc(40);
  }
}




.contactbox__item {
  &:not(:last-of-type):after {
    content: ' /';
  }
}
