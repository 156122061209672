@mixin button-hover-state() {
  background: $button-hover-background;
  color: $button-hover-color;

  path {
    fill: $button-hover-color;
  }
}

@mixin button-arrow-down {
  i {
    display: inline-block;
    vertical-align: middle;
    margin-left: rem-calc(10);
    width: rem-calc(14);

    &.arrow {
      transition: $base-transition;
      transform: rotate(90deg);
    }
  }

  svg {
    width: 100%;
    max-width: 100%;
    height: rem-calc(20);
  }
}

@mixin button() {
  @include border('button');
  background: none;
  color: $button-color;
  cursor: pointer;
  display: inline-block;
  font-family: $button-font;
  font-size: rem-calc(14);
  font-weight: bold;
  letter-spacing: .2em;
  line-height: 1;
  padding: rem-calc(12) rem-calc(24);
  text-decoration: none;
  text-transform: uppercase;
  transition: $base-transition;

  &:hover,
  &:active {
    @include button-hover-state;
  }
}

@mixin button-label {
  @include button;
  @include button-arrow-down;


  path {
    fill: $button-color;
  }
}
