/// Teaser block layout
///
///

@import 'config';
@import 'mixins';

.teaserrow {
  @include module-padding;
  @include clearfix;

  article {
    @include grid-row;
    @include module-margin;

    &:nth-child(even) {
      figure {
        @include ribbon('right');

        @include breakpoint(large) {
          @include grid-column-position(4);
        }

        ~ .teaser {
          @include breakpoint(large) {
            @include grid-column-position(-8);
          }
        }
      }
    }
  }

  figure {
    @include grid-column;
    @include ribbon;
    position: relative;

    @include breakpoint(large) {
      @include grid-column(8);
    }



    ~ .teaser {
      @include grid-column;

      @include breakpoint(large) {
        @include grid-column(4);
      }
    }
  }

  .teaser {
    @include grid-column;

    @include breakpoint(medium) {
      @include grid-column(6);
    }
  }

  img {
    @include responsive-img;
  }

  @at-root .jobs {
    .teaserrow--info {
      .teaser {
        @include breakpoint(medium) {
          @include grid-column(7);
        }
      }

      .infobox {
        @include breakpoint(medium) {
          @include grid-column(5);
        }
      }
    }
  }
}



.teaserrow--info {
  .teaser {
    @include breakpoint(medium) {
      @include grid-column(7);
    }

    @include breakpoint(large) {
      @include grid-column(8);
    }
  }

  .infobox {
    @include grid-column;

    @include breakpoint(medium) {
      @include grid-column(5);
    }

    @include breakpoint(large) {
      @include grid-column(4);
    }
  }
}
