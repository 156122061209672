/// Large hero page image
///
///

@import 'config';
@import 'mixins';

.hero {
  margin-bottom: 3em;

  @include breakpoint(large) {
    @include grid-row;
    max-width: $hero-maxwidth;
  }

  picture {
    position: relative;
  }

  img {
    @include responsive-img;
  }

  &.has-ribbon {
    @include grid-row;

    picture {
      @include grid-column();

      @include breakpoint(large) {
        @include grid-column(11);
        @include grid-column-offset(1);
        @include ribbon;
      }
    }
  }
}
