/// Thumbnail list
///
///

@import 'config';
@import 'mixins';

.infobox__inner {
  @include responsive-font(2.5vw, 20px, 32px, 20px);
  @include border('infobox');
  font-style: italic;
  line-height: 1.3;
  padding: rem-calc(40);

  @include breakpoint(medium) {
    padding: rem-calc(75) rem-calc(40);
    transform: translateY(-(rem-calc(90)));
  }

  p {

    &:first-of-type {
      @include border('heading');
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}


.teaser + .infobox {
  margin-top: rem-calc(30);

  @include breakpoint(medium) {
    margin-top: 0;
  }
}
