/// Content partner detail
///
///

.content-about {
  @include grid-row;

  h2 {
    max-width: 80%;
  }

  article {
    @include module-margin;

    &:not(:first-of-type) {
      .infobox__inner {
        @include responsive-font(2.5vw, 20px, 24px, 20px);

        @include breakpoint(medium) {
          transform: translateY(rem-calc(54));
        }
      }
    }

    &:nth-child(even) {
      .content-about__entry {
        @include breakpoint(medium) {
          @include grid-column-position(4);
        }
      }

      aside {
        @include breakpoint(medium) {
          @include grid-column-position(-8);
        }
      }
    }
  }

  aside {
    @include grid-col;
    @include module-margin;
    transform: translate(0);

    @include breakpoint(medium) {
      @include grid-col(4);
    }
  }
}







.content-about__entry {
  @include grid-col;
  @include module-margin;
  @include font-copy;

  @include breakpoint(medium) {
    @include grid-col(8);
  }
}
