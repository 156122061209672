.form {
  @include font-copy;

  input[type='text'],
  input[type='email'],
  &__select,
  textarea {
    @include font-copy-meta;
    border: 1px solid palette(ui, 'gray-light');
    display: block;
    line-height: 1;
    padding: rem-calc(10) rem-calc(15);
    width: 100%;
  }

  //&__select {
  //  @include button-arrow-down;
  //  padding: 0;
  //  position: relative;
  //
  //  i {
  //    position: absolute;
  //    right: rem-calc($gutter);
  //    top: rem-calc($gutter/1.5);
  //    transform: rotate(-90deg);
  //  }
  //}

  select {
    @include font-copy-meta;
    background: none;
    border: none;
    cursor: pointer;
    appearance: none;
    padding: rem-calc(10) rem-calc(15);
    width: 100%;
  }

  textarea {
    height: rem-calc(140);
  }

  label {
    display: block;
    font-weight: bold;
    margin-bottom: .5em;
  }

  button {
    @include button;
  }
}






.form__row {
  @include breakpoint(large) {
    @include grid-column-row(0);

    .form__field {
      @include grid-column(6, 0);

      &:first-child {
        padding-right: rem-calc($gutter);
      }

      &:last-child {
        padding-left: rem-calc($gutter);
      }
    }
  }
}







.form__field,
.form__messages {
  margin-bottom: rem-calc($gutter*1.5);
}







.form__messages {
  @include font-copy-meta;
  font-style: italic;

  &.error {
    color: palette(ui, 'error');
  }
}
