/// Notification box
///
///

.notification {
  @include module-padding;
  @include clearfix;
}




.notification__wrapper {
  @include grid-row;
  @include module-margin;
}





.notification__inner {
  @include grid-column;
}






.notification__box {
  @include grid-row;
  background-color: palette(ui, 'brand-light');
  color: palette(ui, 'white');
  padding: 2em;
}




.notification__header {
  @include grid-column;

  @include breakpoint(large) {
    @include grid-column(3);
  }
}



.notification__title {
  @include responsive-font(2.5vw, 22px, 36px, 20px);
  color: palette(ui, 'white');
  margin-top: 0;
}



.notification__body {
  @include grid-column;
  @include font-copy;

  @include breakpoint(large) {
    @include grid-column(9);
  }

  p:last-child {
    margin-bottom: 0;
  }
}
