/// Content partner detail
///
///

.content-jobs {
  @include grid-row;
  @include module-margin;

  h2,
  h3 {
    @include font-title-small;
  }

  article {
    @include grid-col;

    @include breakpoint(medium) {
      @include grid-col(8);
    }
  }

  aside {
    @include grid-col;
    @include font-copy-meta;
    font-style: italic;

    @include breakpoint(medium) {
      @include grid-col(4);
    }

    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}



.content-jobs__entry {
  @include module-margin;
  @include font-copy;
}
