/// Language select
///
///

@import 'config';
@import 'mixins';

.publications {
  @include module-margin;
  position: relative;

  > div {
    //height: 0;
    @include font-copy-meta;
  }

  h4 {
    @include button-label;
    height: rem-calc(50);
    margin-top: 0;
  }

  input[type='checkbox'] {
    cursor: pointer;
    position: absolute;
    width: rem-calc(295);
    height: 100%;
    //height: rem-calc(50);
    //line-height: rem-calc(50);
    opacity: 0;
    z-index: 1;

    /// toggle content if checkbox if checked
    &:checked {
      ~ div {
        max-height: none;
      }

      ~ h4 {
        @include button-hover-state;
      }
    }

    &:hover,
    &:active {
      ~ h4 {
        @include button-hover-state;
      }
    }
  }

  h5 {
    margin-top: 2em;
  }

  ul {
    margin-bottom: 1em;
  }
}
