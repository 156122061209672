/// Content partner detail
///
///

.content-fol {
  @include grid-row;
  @include module-margin;

  h2 {
    @include border('heading');
    max-width: 80%;
  }


  article {
    @include grid-col;
    margin-bottom: 3em;
    position: relative;

    @include breakpoint(medium) {
      @include grid-col(8);
    }

    @include breakpoint(large) {
      @include grid-col;
    }
  }
}



.content-fol__inner {
  @include breakpoint(large) {

    background: palette(ui, 'gray-lightest');
    padding: rem-calc($gutter*2);
  }
}




.content-fol__entry {
  @include font-copy;
}
