////
/// Colors
/// @group colors
///
////


/// The main brand color (dark blue)
$color-brand:         #00204a; // blue
$color-brand-dark:    #00122f; // dark blue
$color-brand-light:   #065b89; // light blue
$color-brand-lighter: #c1d6e1; // lighter blue
$color-secondary:     #e31621; // red

//$color-brand: #c69c6e;

/// The main monochrome colors. Use only in palette)
$color-black: black;
$color-white: white;

/// UI colors map for the main theme
///
/// @example
///   .element {
///     color: palette(ui, 'brand');
///   }
$palettes: (
  ui: (
    'brand':            $color-brand,
    'brand-dark':       $color-brand-dark,
    'brand-light':      $color-brand-light,
    'brand-lighter':    $color-brand-lighter,
    'secondary':        $color-secondary,
    'error':        $color-secondary,
    'white':            $color-white,
    'offwhite':         #f7f7f7,
    'gray-dark':        #484848,
    'gray':             #9a9a9a,
    'gray-light':       #c6c6c6,
    'gray-lighter':     #eee,
    'gray-lightest':    #f9f9f9,
    'black':            #00122f
  ),
  vendor: (
    'facebook':   #3b5998,
    'twitter':    #4099ff,
    'gplus':      #c63d2d,
    'pinterest':  #c92228,
    'reddit':     #cee2fa,
    'bloglovin':  #479aff,
    'instagram':  #04558c,
    'untappd':    #fdcd0a,
    'rss':        #ffae00
  )
);

/// Function to parse color palettes
@function palette($palette, $tone: 'brand') {
  @return map-get(map-get($palettes, $palette), $tone);
}



/// The base text color
$base-text-color:         palette(ui, 'gray-dark');

/// The base text color, but a bit lighter
$base-text-color-light:   palette(ui, 'gray');

/// The body background color
$base-background:         palette(ui, 'white');
